import React from "react";
import alibabaCloudLogo from "../../../assets/img/PartnershipsCollaboration/alibabaCloudLogo.svg";

export default function HeroSection() {
  return (
    <div>
      <div className="relative p-4 flex flex-col lg:flex-row h-full  justify-center items-center lg:justify-start lg:px-8 lg:py-20 mx-auto max-w-[1300px]">
        <div className="lg:w-[70%] flex-shrink-0 md:w-[576px] sm:w-[544px]">
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-wide text-gray-900 lg:text-5xl sm:leading-none">
            Strategic Partnership <br></br>with Alibaba Cloud:
          </h2>
          <div>
            <h3 className="mb-5 font-sans text-3xl  tracking-wide font-normal text-blue-800 lg:text-5xl sm:leading-none">
              Elevating Digital Transformation
            </h3>
            <div className="flex justify-center items-center flex-shrink w-[30%] lg:absolute right-4 top-[50%] lg:translate-y-[-50%]">
              <img src={alibabaCloudLogo} alt="alibba cloud logo" />
            </div>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
              At AeroAegis, our mission is to drive innovation and deliver
              cutting-edge solutions that empower businesses in the digital era.
              Our strategic partnership with Alibaba Cloud, a global leader in
              cloud computing and artificial intelligence, enhances our
              capabilities to offer comprehensive services in Big Data, Cloud
              Computing, Edge AI, and the Industrial Internet of Things (IIoT).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
