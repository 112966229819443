import { React, useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Footer from "./pages/Footer";
import Header from "./pages/Header";
import Homepage from "./pages/Homepage/Homepage";

import DiscoverAeroaegis from "./pages/About us/discover_aeroaegis";
import Capabalities from "./pages/About us/aero_aegis_capabilities";
import LeadershipAndTeam from "./pages/About us/Leadership and team";
import PartnershipsPage from "./pages/About us/Partnerships";

import OurValuesPage from "./pages/About us/our_values";

import BlockchainSolution from "./pages/Solutions/Blockchain Integration";
import AiandMLPage from "./pages/Solutions/AI & ML Solutions";
import DataInsightsAnalytics from "./pages/Solutions/Data Insights & Analytics";
import VfxAndAnimationsPage from "./pages/Solutions/Vfx and Animation";
import AutomationRpaPage from "./pages/Expertise/Automation & System Integration";
import CloudAndMigration from "./pages/Expertise/Cloud Solutions & Migration";
import SreAndDevopsPAge from "./pages/Expertise/Operational Excellence with SRE & DevOps";

import MS360Page from "./pages/ProductsPage/MS360";
import SysARPage from "./pages/ProductsPage/SysAR";
import SmartChaosLab from "./pages/ProductsPage/SmartChaosLab";
import InfraVuePage from "./pages/ProductsPage/InfraVue";
import AeroPulseAiPage from "./pages/ProductsPage/AeroPulseAi";
import AeroaegisIIOTPage from "./pages/ProductsPage/AeroaegisIIOT";
import AllProducts from "./pages/ProductsPage/AllProducts";

import WhitePapers from "./pages/WhitePapers";
import WhitePaper1 from "./pages/Whitepapers_Detail/whitepaper1";
import WhitePaper2 from "./pages/Whitepapers_Detail/whitepaper2";
import WhitePaper3 from "./pages/Whitepapers_Detail/whitepaper3";

// import  QuantumComputing from './pages/QuantumComputing'
// import  AIAndMachineLearning from './pages/AI and Machine Learning'
// import  ARAndVR from './pages/AR and VR'
// import  IOT from './pages/Iot and Edge Computing'
// import  BlockchainTechnology from './pages/Blockchain Technology'

import ContactusPage from "./pages/ContactUS/Contact";
import DisclaimerPage from "./pages/DisclaimerPage";
import TermsAndConditions from "./pages/TermsAndConditions/TnCContent";
import CsrPage from "./pages/CSR";
import CareersPage from "./pages/Careers";
import InvestorRelations from "./pages/Investor Relations";
import LocationsPage from "./pages/Locations";
import NewsPage from "./pages/News";
import AlibabaCloud from "./pages/Patnerships/AlibabaCloud/AlibabaCloud.js";
function App() {
  const location = useLocation();
  // Scroll to top on route change
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, [location.pathname]);

  // Scroll Top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" Component={Homepage} />
        <Route path="/aeroaegis-capabilities" Component={Capabalities} />
        // new routes
        {/* <Route path="/quantum-computing" Component={QuantumComputing} />
      <Route path="/ai-and-machine-learning" Component={AIAndMachineLearning} />
      <Route path="/blockchain-technology" Component={BlockchainTechnology} />
      <Route path="/iot-edge" Component={IOT} />
      <Route path="/ar-vr" Component={ARAndVR} /> */}
        <Route path="/discover-aeroaegis" Component={DiscoverAeroaegis} />
        <Route path="/leadership-team" Component={LeadershipAndTeam} />
        <Route
          path="/partnerships-collaborations"
          Component={PartnershipsPage}
        />
        <Route path="/ai-ml-solutions" Component={AiandMLPage} />
        <Route path="/vfx-animation" Component={VfxAndAnimationsPage} />
        <Route path="/blockchain-solution" Component={BlockchainSolution} />
        <Route
          path="/automation-system-integration"
          Component={AutomationRpaPage}
        />
        <Route
          path="/data-insights-analytics"
          Component={DataInsightsAnalytics}
        />
        <Route path="/our-values" Component={OurValuesPage} />
        <Route
          path="/operational-excellence-with-sre-devops"
          Component={SreAndDevopsPAge}
        />
        <Route path="/maturity-spectrum-360" Component={MS360Page} />
        <Route path="/aeroedge-iiot" Component={AeroaegisIIOTPage} />
        <Route
          path="/cloud-solutions-migration"
          Component={CloudAndMigration}
        />
        <Route path="/sys-ar" Component={SysARPage} />
        <Route path="/products" Component={AllProducts} />
        <Route path="/smartchaos-lab" Component={SmartChaosLab} />
        <Route path="/infra-vue" Component={InfraVuePage} />
        <Route path="/aeropulse-ai" Component={AeroPulseAiPage} />
        <Route path="/contact-us" Component={ContactusPage} />
        <Route path="/white-papers" Component={WhitePapers} />
        <Route
          path="/navigating-digital-transformation-unleashing-power-maturity-spectrum-360"
          Component={WhitePaper1}
        />
        <Route
          path="/smartchaoslab-revolutionizing-application-reliability-with-chaos-engineering"
          Component={WhitePaper2}
        />
        <Route
          path="/blueprinting-tomorrows-infrastructure-today"
          Component={WhitePaper3}
        />
        <Route path="/disclaimer" Component={DisclaimerPage} />
        <Route path="/terms-conditions" Component={TermsAndConditions} />
        <Route path="/csr" Component={CsrPage} />
        <Route path="/careers" Component={CareersPage} />
        <Route path="/investor-relations" Component={InvestorRelations} />
        <Route path="/locations" Component={LocationsPage} />
        <Route path="/news-room" Component={NewsPage} />
        <Route path="/partnerships/alibaba-cloud" Component={AlibabaCloud} />
      </Routes>
      <div className="fixed bottom-8 right-8 z-50">
        <button
          onClick={scrollToTop}
          className="bg-[#232f7f] text-white rounded-full p-3 hover:bg-[#596191] focus:outline-none focus:shadow-outline"
          aria-label="Scroll to top"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            ></path>
          </svg>
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default App;
