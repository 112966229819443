import { FaLink } from "react-icons/fa";
import React from "react";
import aeroaegis from "../../../assets/img/PartnershipsCollaboration/aeroaegis.png";
import alibabaCloudLogo from "../../../assets/img/PartnershipsCollaboration/alibabaCloudLogo.svg";

export default function LastSection() {
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-white to-blue-50 py-20">
      <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,white,transparent)] dark:bg-grid-slate-700/25"></div>

      <div className="relative mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center justify-center space-y-8 sm:flex-row sm:space-x-12 sm:space-y-0">
          <div className="group relative">
            <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-blue-600 to-blue-400 opacity-0 blur transition duration-200 group-hover:opacity-20"></div>
            <div className="w-[128px]">
              <img className="object-cover w-fit  " src={aeroaegis} alt="" />
            </div>
          </div>

          <FaLink className="hidden h-6 w-6 text-stone-500 sm:block " />

          <div className="group relative">
            <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-orange-500 to-orange-300 opacity-0 blur transition duration-200 group-hover:opacity-20"></div>
            <div className="w-fit">
              <img src={alibabaCloudLogo} alt="alibaba cloud logo" />
            </div>
          </div>
        </div>

        <div className="mt-16 space-y-8 text-center">
          <div className="space-y-6">
            <h2 className="mx-auto max-w-3xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Together with Alibaba Cloud, AeroAegis is committed to delivering
              solutions
            </h2>
            <p className="mx-auto max-w-2xl text-lg leading-relaxed text-gray-600">
              We provide services that are not only technologically advanced but
              also aligned with your business objectives. Our combined expertise
              ensures that you receive services that are reliable, efficient,
              and tailored to your specific needs.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
